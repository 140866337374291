const userLevelNudgeType = Object.freeze({
    EMAIL_VERIFICATION: 'EmailVerification',
    USER_QUESTIONNAIRE: 'UserQuestionnaire',
});

const InvestmentTimeFrame = Object.freeze({
    FEW_WEEKS: 'FewWeeks',
    FEW_MONTHS: 'FewMonths',
    NOT_PLANNING: 'NotPlanning',
    NOT_AVAILABLE: 'NotAvailable',
});

export { userLevelNudgeType, InvestmentTimeFrame };
