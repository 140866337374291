import { hasWindow } from '@services/http.service';
import {
    ADMIN_LOGIN_URL,
    GET_USER_WHATAPP_STATUS,
    SEND_OTP_URL,
    USER_LOGIN_URL,
    VERIFY_OTP_URL,
} from '@services/urls/session';
import * as CsrHttpService from "@http/csr-http.service";
import * as SsrHttpService from "@http/ssr-http.service";
import { determineAction, triggerCustomGaEvent } from '@helpers/miscelleanous';
import { getReferralCode, getReferralEntityType, setAuth } from './identity.service';
import { investorService } from './middlewares/investor.middleware';
import { loginSignupEventsName } from '@helpers/ga-events-name-enum';
import { userLevelNudgeType } from '@helpers/enums/user-level-nudge-type';
import KycStatusEnums from '@helpers/kyc-status';
import routes from '@helpers/routes';
import { isIndividual } from '@helpers/utils/investor-utils';
import { addParam } from '@helpers/routes/helper';
import {
    logEvent, moengageAttributesConstants,
    moengageEventArgsConstants,
    moengageEventConstants,
    setUserAttribute,
    setUserData
} from "@services/moengage";
import { createDealInterest } from './bond-details.service';
import { isValidResponseEntity } from '@helpers/utils';

export const adminLogin = (username, password) => {
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(ADMIN_LOGIN_URL(), { username, password });
    }
    return SsrHttpService.postWithOutAuth(ADMIN_LOGIN_URL(), { username, password });
};

export const loginUser = (mobile, otpIdList, userInput, isMigratedUser, accessToken) => {
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(USER_LOGIN_URL(), { mobile, otpIdList, accessToken, isMigratedUser, ...userInput });
    }
    return SsrHttpService.postWithOutAuth(USER_LOGIN_URL(), { mobile, otpIdList, accessToken, isMigratedUser, ...userInput });
};

// details: {mobile, context, email, countryCode, entityType="Ifa" for FinancialAdvisor else omit it}
export const resendOtp = (details) => {
    return sendOtp({ ...details, resend: true });
};

// details: {mobile, context, otp}
export const verifyOtp = (details) => {
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(VERIFY_OTP_URL(), details);
    }
    return SsrHttpService.postWithOutAuth(VERIFY_OTP_URL(), details);
};

// details: {mobile, context, email, countryCode, entityType="Ifa" for FinancialAdvisor else omit it}
export const sendOtp = (details) => {
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(SEND_OTP_URL(), details);
    }
    return SsrHttpService.postWithOutAuth(SEND_OTP_URL(), details);
};

export const getUserWhatsAppStatus = (mobile, ctx = null) => {
    const url = GET_USER_WHATAPP_STATUS(mobile);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const onLoginSignupSuccess = async ({
    user,
    isSignup,
    router,
    opportunityId,
    userInput,
    authStoreLogin,
    fetchNudges,
    showDealInterestModal,
}) => {
    triggerCustomGaEvent(
        isSignup
            ? loginSignupEventsName.SIGNUP_OTP
            : loginSignupEventsName.LOGIN_OTP,
        {
            mobile: userInput.mobile,
            name: userInput.name,
            email: userInput.email,
            investmentTimeFrame: userInput.investmentTimeFrame,
        }
    );
    const { id, userId, accountType } = user;

    setAuth(user);
    await setUserData({auth: user});
    if (isSignup) {
        setUserAttribute({
            attributeName: moengageAttributesConstants.WHATSAPP_OPT_IN,
            attributeValue: userInput?.whatsappOptIn,
        });
        logEvent({
            eventName: moengageEventConstants.SIGNUP_SUCCESS,
            parameters: {
                [moengageEventArgsConstants.INVESTMENT_TIME_FRAME]: userInput.investmentTimeFrame
            }
        });
    } else {
        logEvent({eventName: moengageEventConstants.LOGIN_SUCCESS});
    }
    if (
        router?.asPath === '/refer-and-earn' &&
        user.entityType !== 'ifa'
    ) {
        window.location.reload('/refer-and-earn');
        return;
    }
    const userLevelNudges = await fetchNudges(userId);

    const shouldRedirectToQuestionnaire =
        userLevelNudges.nudges?.findIndex(
            (nudge) => nudge.nudgeType === userLevelNudgeType.USER_QUESTIONNAIRE
        ) >= 0;

    const referralCode = getReferralCode();
    const referralEntity = getReferralEntityType();
    const isReferAndEarnPage = router.pathname === '/referral-program';
    let kycStatus = null
    if (!isSignup && isIndividual(accountType)) {
        const investor = await investorService.get(user.id);
        kycStatus = investor?.kycStatus;
    }
    if (kycStatus)
        setUserAttribute({
            attributeName: moengageAttributesConstants.KYC_STATUS,
            attributeValue: kycStatus || ""
        });
    // if redirect query param exist in url then using that
    // otherwise using default path
    const redirectUrl = router?.query?.redirect || '';


    let basePath = '';
    let queryParams = '';
    if (redirectUrl) {
        basePath = redirectUrl;
    } else if (shouldRedirectToQuestionnaire || isSignup) {
        /**
         * Post signup we require to fire conversion pixels,
         * and for this we have to identify if it was a signup or login
         * for this we are using isreturnvisit and referrer code query string in GTM
         * If we change the landing page, use the same query params for that page as well
         */
        basePath = routes.questionnaire.base;
        if (referralCode && isSignup) {
            queryParams =
                referralEntity === 'usoc'
                    ? addParam(queryParams, `usoc=${referralCode}`)
                    : addParam(queryParams, `referrer=${referralCode}`);
        }
        queryParams = addParam(queryParams, `isreturnvisit=${!isSignup}`);
        if (isReferAndEarnPage) {
            queryParams = addParam(queryParams, 'referandearn=true');
        } else if (opportunityId) {
            queryParams = addParam(queryParams, `opportunityId=${opportunityId}`);
        }
        } else if (!kycStatus || kycStatus !== KycStatusEnums.VERIFIED) {
            basePath = routes.welcome.base;
            queryParams = addParam(queryParams, 'isReturning=true');
            if (isReferAndEarnPage) {
                queryParams = addParam(queryParams, 'referandearn=true');
            } else if (opportunityId) {
                queryParams = addParam(queryParams, `opportunityId=${opportunityId}`);
            }
        } else {
            basePath = isReferAndEarnPage
                ? `/refer-and-earn`
                : opportunityId
                    ? `/opportunity/${opportunityId}`
                    : `/opportunities`;
        }
    if (router.query.interestedIsin) {
        const dealInterestPayload = {
            investorId: id !== userId ? id : null,
            opportunityId: router.query.opportunityId || null,
            userId: userId || id,
            isin: router.query.interestedIsin,
        };
        const createInterestRecord = await createDealInterest(
            dealInterestPayload
        );

        if (isValidResponseEntity(createInterestRecord)) {
            const [action, modalDetails] = determineAction(
                isSignup,
                router.query.opportunityId,
                false,
                decodeURIComponent(router.query.issuer)
            );
            if (action === 'SHOW_MODAL') {
                authStoreLogin(user, false);
                showDealInterestModal(
                    `${basePath}${queryParams}`,
                    modalDetails
                );
                return;
            }
        } else {
            router.push(`${basePath}${queryParams}`);
        }
    }
    router.push(`${basePath}${queryParams}`);
    authStoreLogin(user, false);
};
