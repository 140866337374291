import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import { hasWindow } from '@services/http.service';
import {
    CREATE_DEAL_INTEREST,
    GET_ALL_BONDS,
    GET_BOND_DETAILS_BY_ID,
    GET_BOND_DETAILS_BY_ISIN,
    GET_BOND_SEARCH_RESULTS,
} from './urls/bondDetails';

export const getBondDetailsByIsin = (payload, ctx = null) => {
    const url = GET_BOND_DETAILS_BY_ISIN();
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(url, payload);
    }
    return SsrHttpService.postWithOutAuth(url, payload, ctx);
};

export const getBondSearchResults = (payload, ctx = null) => {
    const url = GET_BOND_SEARCH_RESULTS();
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(url, payload);
    }
    return SsrHttpService.postWithOutAuth(url, payload, ctx);
};

export const getBondDetailsById = (isin, ctx = null) => {
    const url = GET_BOND_DETAILS_BY_ID(isin);
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url, ctx);
};

export const getAllBonds = (ctx = null) => {
    const url = GET_ALL_BONDS();
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url, ctx);
};

export const createDealInterest = (data) => {
    const url = CREATE_DEAL_INTEREST();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data);
};
