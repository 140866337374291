import Badge from '@atoms/Badge';
import { Image, Text } from '@atoms/index';
import texts from '@components/Auth/en.json';

/// if descriptionComponent is passed, it is given higher priority and displayed instead of description.
// to show otpDestination in new lines with/out edit, pass array to otpDestination and onClickEdit
const EnterOTPCard = ({
    title,
    descriptionComponent,
    description,
    otpDestination,
    onClickEdit,
    errorMessage,
    otpInputComponent,
    resendOtpComponent,
    isVerified,
    showInnerCard = true,
}) => (
    <div className={`${showInnerCard ? 'p-4 bg-gray-50 rounded-xl' : ''} flex flex-col items-start`}>
        {title && <Text
            content={title}
            className="p3-medium mb-2 text-primary-500"
        />}
        <div className={`w-full ${showInnerCard ? '' : 'flex flex-col items-center'}`}>
            {descriptionComponent
                ? descriptionComponent
                : (
                    <>
                        <Text
                            content={description}
                            className={`p4-regular text-gray-900 ${showInnerCard ? '' : 'text-center'}`}
                        />
                        <div className={`flex flex-col gap-y-1 mt-1 ${showInnerCard ? 'items-start' : 'items-center'}`}>
                            {
                                otpDestination.map((destination, index) =>
                                    <div key={index} className='flex gap-x-2'>
                                        <Text
                                            content={destination}
                                            className="p4-medium text-basicBlack"
                                        />
                                        {onClickEdit?.[index] && <span
                                            onClick={onClickEdit?.[index]}
                                            className="cursor-pointer"
                                        >
                                            <Image
                                                src="/images/kyc/edit-icon.svg"
                                                width="24"
                                                height="24"
                                            />
                                        </span>}
                                    </div>
                                )}
                        </div>
                    </>
                )}
            <div className='mt-4 flex gap-x-4 items-center'>
                {otpInputComponent}
                {isVerified && showInnerCard && (
                    <>
                        <Badge borderRadiusClass='rounded-lg' className='hidden md:block bg-semantic-success-light text-semantic-success-base'
                            content={<div className='flex gap-x-1 items-center'>
                                <Image src='/images/sucessfullGreenTick.png' alt='verified' className="w-4 h-4" />
                                <Text content={texts?.Verified} className='p4-semibold' />
                            </div>} />
                        <Image src='/images/sucessfullGreenTick.png' alt='verified' className="md:hidden w-4 h-4" />
                    </>
                )}
            </div>
            {errorMessage && (
                <Text
                    content={errorMessage}
                    className="mt-2 text-semantic-error-base p4-medium"
                />
            )}
            {!isVerified && (
                <div className='mt-4'>
                    {resendOtpComponent}
                </div>
            )}
        </div>
    </div>
);

export default EnterOTPCard;