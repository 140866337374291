import { Text } from '@atoms/index';
import { otpExpiryTime } from "@helpers/constants";
import { formatTimeForTimer } from '@ui/helpers/miscelleanous';
import useOtpInput from "@ui/hooks/useOtpInput";
import { useState, useEffect, useRef } from "react";
import Button from '@ui/atoms/design-system/Button';

const useOtpVerifier = ({ resendText = 'Resend OTP', shouldFocusByDefault = true, id, otpContext }) => {
    const [seconds, setSeconds] = useState(otpExpiryTime);
    const [errorMessage, setErrorMessage] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [isResendButtonLoading, setIsResendButtonLoading] = useState(false);
    const otpFieldValues = useOtpInput({
        setMessage: setErrorMessage,
        shouldFocusByDefault,
        id: id,
    });
    const timerRef = useRef();

    useEffect(() => {
        if (seconds > 0) {
            timerRef.current = setTimeout(() => setSeconds(seconds - 1), 1000);
        }

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [seconds]);

    useEffect(() => {
        otpFieldValues.setDisabled(isVerified);
    }, [isVerified]);

    const resetSeconds = () => setSeconds(otpExpiryTime);

    const cancelTimer = () => {
        clearTimeout(timerRef.current);
        setSeconds(0);
    }

    return {
        ...otpFieldValues,
        otpContext,
        seconds,
        resetSeconds,
        errorMessage,
        setErrorMessage,
        isVerified,
        setIsVerified,
        cancelTimer,
        ResendOtpComponent: (onResendOtp) => {
            const onClickResend = async () => {
                setIsResendButtonLoading(true);
                await onResendOtp();
                setIsResendButtonLoading(false);
            }
            return seconds > 0 ? (
                <Text content={`${resendText} in ${formatTimeForTimer(seconds)} seconds`} className="p4-medium text-gray-400" />
            ) : (
                <Button buttonType='text' buttonSize='small' type='button' buttonText={resendText} onClick={onClickResend} isLoading={isResendButtonLoading} />
            );
        }
    };
}

export default useOtpVerifier;