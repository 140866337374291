import { otpLength } from '../helpers/constants';
import { isNaN } from 'lodash';
import { useEffect, useRef, useState } from 'react';

const useOtpInput = ({ id = "mobileNumberConfirmationOtp", setMessage, shouldFocusByDefault = true, widthClass = 'w-[240px]' }) => {
    const [otpValue, setOtpValue] = useState('');
    const [otpDisplayValue, setOtpDisplayValue] = useState('');
    const inputRef = useRef(null);
    const [disabled, setDisabled] = useState(false);

    const onChangeOtpHandler = (e) => {
        setMessage('');
        const input = e.target.value.split(' - ').join('');
        const inputLength = input.length;
        const insertedValue = input[inputLength - 1];
        if (inputLength > otpLength) {
            return;
        }
        if (!isNaN(Number(insertedValue)) || inputLength === 0) {
            setOtpValue(input);
        }
    };

    useEffect(() => {
        const value = otpValue.split('').join(' - ');
        setOtpDisplayValue(value);
    }, [otpValue]);

    useEffect(() => {
        if (inputRef?.current && shouldFocusByDefault) {
            inputRef.current.focus();
        }
    }, [shouldFocusByDefault]);

    return {
        otpValue,
        otpDisplayValue,
        inputRef,
        setOtpValue,
        onChangeOtpHandler,
        setDisabled,
        OtpInputComponent: (
            <>
                <input
                    type="text"
                    inputMode="numeric"
                    onChange={onChangeOtpHandler}
                    ref={inputRef}
                    value={otpDisplayValue}
                    className={`box-border ${widthClass} h-12 px-4 text-center border-2 rounded-lg md:h-12 border-gray-300 p2-semibold ${disabled ? 'bg-gray-100 text-gray-400' : 'bg-basicWhite text-gray-900'}`}
                    style={{
                        letterSpacing: otpDisplayValue ? '0px' : '5px'
                    }}
                    placeholder={"- - - - - -"}
                    id={id}
                    disabled={disabled}
                    autoComplete="off"
                />
            </>
        ),
    };
};

export default useOtpInput;
