import { UrlParamsReplace } from './index';

export const GET_BOND_DETAILS_BY_ISIN = () =>
    UrlParamsReplace('/bondDirectory/search');

export const GET_BOND_SEARCH_RESULTS = () =>
    UrlParamsReplace('/bondDirectory/bonds/search');

export const GET_ALL_BONDS = (isin) => UrlParamsReplace('/bondDirectory/bonds');

export const GET_BOND_DETAILS_BY_ID = (isin) =>
    UrlParamsReplace('/bondDirectory/isin/:isin', { isin });

export const CREATE_DEAL_INTEREST = () => UrlParamsReplace('/deal-interest');